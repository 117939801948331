import React, { useContext, useEffect } from 'react';
import { Form, Formik } from "formik";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import InputLabelErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import CollaboratorService from "../../../../../../services/CollaboratorService";
import { StateRequest } from '../../../../Ings/Dir/Objectifs/ObjectifsViewIngDir';
import SchemaObjectiveTemp from '../../../../../../components/Molecules/Cards/Collaborator/SchemaObjectiveTemp';

const FormEditPrevTime = ({ id, setShowPopUp, prevTime, realTime, filteredUser }) => {

	const { stateRequest, setStateRequest } = useContext(StateRequest);

	const floatToTime = (float) => {
		const hours = Math.floor(float); // Partie entière pour les heures
		const minutes = Math.round((float - hours) * 60); // Partie décimale convertie en minutes

		// Formatage en HH:MM avec des zéros devant si nécessaire
		const formattedHours = String(hours).padStart(2, '0');
		const formattedMinutes = String(minutes).padStart(2, '0');

		return `${formattedHours}:${formattedMinutes}`;
	};

	const timeToFloat = (time) => {
		const [hours, minutes] = time.split(':').map(Number);
		const floatTime = hours + (minutes / 60);
		return parseFloat(floatTime.toFixed(4)); // Arrondi à 2 décimales
	  }

	const onSubmit = async (values) => {
		const newPrevTime = timeToFloat(values.userPrevTime)
		const newRealTime = timeToFloat(values.userRealTime)

		const request = await CollaboratorService.patchPrevTemp(`projects/${id}/context/engineer/change-duration`, {
			"estimatedDuration": newPrevTime,
			"effectiveDuration": newRealTime,
			"userIri": `/api/users/${filteredUser.id}`,
		});
		if (request.request.status === 200) {
			setStateRequest("success edit");
		} else {
			setStateRequest("error");
		}
		setShowPopUp(false);
	};

	useEffect(() => {
		// Convertir prevTime et realTime en format HH:MM
		const formattedPrevTime = floatToTime(prevTime);
		const formattedRealTime = floatToTime(realTime);

		initialValues.userPrevTime = formattedPrevTime;
		initialValues.userRealTime = formattedRealTime;
	}, [id]);

	const initialValues = {
		userPrevTime: floatToTime(prevTime), // Conversion initiale
		userRealTime: floatToTime(realTime), // Conversion initiale
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={SchemaObjectiveTemp}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					<Form>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"time"} label={"Temps prévisionnel"} name={"userPrevTime"} />
							<InputLabelErrorMessage type={"time"} label={"Temps réel"} name={"userRealTime"} />
						</div>
						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Modifier"} />
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormEditPrevTime;
