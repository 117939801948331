import React, {useEffect, useState} from 'react';

import ButtonSubmit from '../../../../../components/Atomes/Buttons/ButtonSubmit';
import PopupProjectStageDepositVir from './Popup/PopupDepositVir';
import { ProjectStageService } from '../../../../../services/ProjectStageService';

const ProjectStageDepositVir = ({projectInvoice}) => {

  const [isTextCopied, setIsTextCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [reference, setReference] = useState("");
  
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsTextCopied(true);
    setTimeout(() => {
      setIsTextCopied(false);
    }, 2000);
  }

  const getReference = async () => {
		const request = await ProjectStageService.getReference(projectInvoice.id)
		if(request.request.status === 200) {
			setReference(request.data.reference)
		}
	}

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  }

  useEffect(() => {
		getReference()
	}, [])

  return (
    <section className='project-step-payment' >
      <div className='project-step-payment__ribIndicator'>
        <p className='text-primary'>Vous avez choisi de régler le paiement d'acompte par virement bancaire. Pour que nous validions dès maintenant le paiement, nous vous invitons à charger votre preuve de virement. Pour faciliter le rapprochement de votre virement bancaire avec votre projet, nous vous invitons à remplir la référence de virement unique.</p>
      </div>

      <div className='project-step-payment__rib'>
        <h4>Relevé d'Identité Bancaire (RIB)</h4>
        {
          isTextCopied && (
            <span className='text-tertiary color-white toast-copy'>Copié dans le presse papier</span>
          )
        }
        <div className='project-step-payment__rib-container'>

          <div className='project-step-payment__rib-column'>

            {/* TODO MBE-305: ici */}

          <div className='project-step-payment__rib-item'>
							<p className='text-primary'>Référence : <span style={{color:"#F9564F", fontSize:'18px', fontWeight:"bold" }}>{reference}</span></p>
							<img src="/images/pictos/copy-paste-primary.svg" alt="copy paste icon"
							     onClick={() => handleCopy("Mon bureau d'études")}/>
						</div>
            
            <div className='project-step-payment__rib-item'>
              <p className='text-primary'>Titulaire du compte : ATELIER CPLUSM MBE</p>
              <img src="/images/pictos/copy-paste-primary.svg" alt="copy paste icon" onClick={() => handleCopy("Mon bureau d'études")} />
            </div>

            <div className='project-step-payment__rib-item'>
              <p className='text-primary'>Domiciliation bancaire : 5 Villa du Bois d'Orme, 75019 Paris</p>
              <img src="/images/pictos/copy-paste-primary.svg" alt="copy paste icon" onClick={() => handleCopy("5 Villa du Bois d'Orme 75019 Paris")} />
            </div>
          </div>

          <div className='project-step-payment__rib-column'>
            <div className='project-step-payment__rib-item'>
              <p className='text-primary'>IBAN : FR76 1513 5004 6008 0007 5965 618</p>
              <img src="/images/pictos/copy-paste-primary.svg" alt="copy paste icon" onClick={() => handleCopy("FR76 1513 5004 6008 0007 5965 618")}  />
            </div>
            <div className='project-step-payment__rib-item'>
              <p className='text-primary'>BIC : CEPAFRPP513</p>
              <img src="/images/pictos/copy-paste-primary.svg" alt="copy paste icon" onClick={() => handleCopy("CEPAFRPP513")}  />
            </div>
          </div>

        </div>
      </div>

      <div className='project-step-payment__action'>
        <ButtonSubmit 
          title={'Charger une preuve de virement'}
          styleButton={"btn-default btn-default-primary"}
          onClick={handleShowPopup}
        />
      </div>

      <PopupProjectStageDepositVir 
        showPopup={showPopup}
        closeAction={handleShowPopup}
        projectInvoice={projectInvoice}
      />
    </section>
  )
}

export default ProjectStageDepositVir;