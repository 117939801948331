import api from "./APIService";
import axios from "axios";
import fileDownload from 'js-file-download';
import { ConvertObjectToUrlParams } from "../utils/StringUtils";

// project
const getProjects = async (params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`projects/context/client`, params))
};
const getSingleProject = async (id) => {
  let request = await api.getAllData(`/projects/${id}/context/client`);
  if (request.request.status === 200) {
    return request.data;
  }
};

// signature
const getCGVU = async () => {
  return await api.getAllData('/configurations/cgvu')
}
const billCreateSignature = async (id, body) => {
  return await api.postBody(`/projects/${id}/create-signature`, body);
}
const billVerifySignature = async (id, project) => {
  return await api.patch(`/projects/${id}/sign`, {});
}

// payment
const postPayCb = async (id, body) => {
  return await api.postBody(`/bills/${id}/pay-card`, body)
}
const postPayVir = async (id, body) => {
  return await api.postBody(`/bills/${id}/pay-transfer`, body)
}
const postBillVirProof = async (id, proof) => {
  let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  const file = new FileReader();
  file.readAsDataURL(proof);
  const billProof = axios.create({
    baseURL: process.env.REACT_APP_PROD_API_URL,
    headers: {
      Authorization: `Bearer ${user ? user.token : ""}`,
      'Content-Type': `multipart/form-data`
    }
  })
  return await billProof.post(`bills/${id}/upload-transfer-proof`, { file: proof })
}
const billVerifyPayment = async (id) => {
  return await api.getAllData(`/bills/${id}/pay-card`)
}


// visit
const delProjectVisite = async (id) => {
  return await api.del(`/bookings/${id}`)
}

// pri
const getPRI = async (id, filename) => {
  const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  axios.get(process.env.REACT_APP_PROD_API_URL + `medias/${id}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    responseType: 'blob',
  })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}
const patchPRI = async (id, body) => {
  return await api.patch(`/projects/${id}/context/client`, body)
}

// marketplace
const getPartnersActive = async () => {
  return await api.getAllData(`partners?active=true`)
}
const postDataToPartner = async (id, body) => {
  return await api.patch(`/partners/${id}/send-client`, body)
}

// study
const patchStudy = async (id, body) => {
  return await api.patch(`/studies/${id}/validate`, body)
}

// medias
const getProjectMedias = async (id) => {
  return await api.getAllData(`/projects/${id}/medias`)
}

const getAllPdf = async () => {
  return await api.getAllData(`/study-references`)
}

const patchAppendices = async (id, body) => {
  return await api.patch(`/studies/${id}/appendices`, body)
}

const getProjectMedia = async (url, filename) => {
  const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  axios.get(process.env.REACT_APP_PROD_URL + url, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    responseType: 'blob',
  })
    .then((res) => {
      fileDownload(res.data, filename)
    })

}
const postProjectMedia = async (infos) => {
  let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  const file = new FileReader();
  file.readAsDataURL(infos.media);
  const media = axios.create({
    baseURL: process.env.REACT_APP_PROD_API_URL,
    headers: {
      Authorization: `Bearer ${user ? user.token : ""}`,
      'Content-Type': `multipart/form-data`
    }
  })
  return await media.post(`/medias`,
    {
      file: infos.media,
      title: infos.media.name,
      projectIri: `/api/projects/${infos.projectId}`,
      categoryType: infos.categoryType
    })
}
const delProjectMedia = async (id) => {
  return await api.del(`/media/${id}`)
}

const getReference = async (id) => {
  return await api.getAllData(`/bills/${id}/transfer-reference`)
}

export const ProjectStageService =
{
  // project
  getProjects,
  getSingleProject,

  // signature
  getCGVU,
  billCreateSignature,
  billVerifySignature,

  // payment
  postPayCb,
  postPayVir,
  postBillVirProof,
  billVerifyPayment,

  // visit
  delProjectVisite,

  // pri
  getPRI,
  patchPRI,

  // marketplace
  getPartnersActive,
  postDataToPartner,

  // study
  patchStudy,
  getAllPdf,
  patchAppendices,

  // medias
  getProjectMedias,
  getProjectMedia,
  postProjectMedia,
  delProjectMedia,

  // reference
  getReference,
};
