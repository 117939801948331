import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import RolesList from "../../../../config/RolesList";

import PopupDeleteMediaComs from './Popup/PopupDeleteMedia';
import PopupAddMediaComs from './Popup/PopupAddMedia';

import { useAuth } from "../../../../services/useAuth";
import { ComService } from '../../../../services/ComService';
import exportFileService from '../../../../services/ExportFileService';

import FileMimeType from '../../../../utils/FileMimeType';
import ProjectNiceType from '../../../../utils/ProjectNiceType';
import ProjectNiceStage from '../../../../utils/ProjectNiceStage';

const ProjectMediasComs = () => {

  const { projectId } = useParams();
  const basicUrl = process.env.REACT_APP_PROD_URL;
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState(null);
  const [medias, setMedias] = useState([]);
  const [mediaToDelete, setMediaToDelete] = useState(null);
  const [mediaUploaded, setMediaUploaded] = useState(false);
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState(null);
  const [showPopupAdd, setShowPopupAdd] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    getProject();
  }, [])

  useEffect(() => {
    getMedias();
  }, [mediaUploaded, showPopupDelete])

  const { role } = useAuth();
  const isClient = role.includes(RolesList.CUSTOMER) || role.includes(RolesList.ARCHITECTE)

  const validFileExtensions = {
    "media-project": ['png', 'jpeg', 'jpg'],
    "media-plan": ['pdf', 'dwg', 'skp', 'rvt', 'dxf', 'png', 'jpeg', 'jpg', 'dwg'],
    "media-calc": ['xlsx', 'xls', 'docx', 'pdf', 'doc'],
    "media-engineer": ['xlsx', 'xls', 'docx', 'pdf', 'doc', 'png', 'jpeg', 'jpg', 'dwg', 'mp4', 'MP4']
  };

  const isValidFileType = (fileName, fileType) => {
    console.log(fileName);
    let isValid = fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
    return isValid;
  }

  const uploadFile = (e, category, inputId) => {
    if (e !== undefined) {

      if (!isValidFileType(e.name, category)) {
        setErrorFile({
          isError: true,
          message: 'Le format du fichier n\'est pas valide, format accepté : ' + validFileExtensions[category].join(', ')
        });
      } else {
        setErrorFile({
          isError: false,
          message: ''
        });
      }

      if (e.size > 15000000) {
        setErrorFile({
          isError: true,
          message: 'La taille du fichier est trop grande, taille maximum : 15Mo'
        });
      }

      setFile({
        media: e,
        categoryType: category,
        projectId: projectId
      })
      document.getElementById(inputId).value = null;
      setShowPopupAdd(true);
    }
  }


  const getProject = async () => {
    let projectInfos = await ComService.getSingleProject(projectId);
    setProjectStepVerificator(true);
    setProject(projectInfos);
  }

  const getMedias = async () => {
    let request = await ComService.getProjectMedias(projectId);
    if (request.status === 200) {
      let medias = request.data['hydra:member'];
      let mediasSorted = [];
      mediasSorted['Administratif'] = [];
      mediasSorted['Image projet'] = [];
      mediasSorted['Plan'] = [];
      mediasSorted['Feuille de calcul'] = [];
      mediasSorted['Document ingénieur'] = [];

      medias.forEach(media => {
        if (media.isAdministrative) {
          mediasSorted['Administratif'].push(media);
        } else if (media.category === 'media-project') {
          mediasSorted['Image projet'].push(media);
        } else if (media.category === 'media-plan') {
          mediasSorted['Plan'].push(media);
        } else if (media.category === 'media-calc') {
          mediasSorted['Feuille de calcul'].push(media);
        } else if ((media.category === 'media-engineer') || (media.category === 'media-study-execution-class-generated') ||(media.category === 'media-study-execution-class-static') ) {
          mediasSorted['Document ingénieur'].push(media);
        }
      });

      setMedias(mediasSorted);
    } else {
      toast.error('Une erreur est survenue');
    }
  }

  const downloadAvisDePassage = (endpoint) => {
    exportFileService.exportMedia(endpoint, 'avis-de-passage', "pdf")
    toast.success('Téléchargement en cours');
  }

  const downloadStudy = () => {
    exportFileService.exportFile('/studies/' + project.study.id, 'dossier-etude-structure', {}, "pdf")
    toast.success('Téléchargement en cours');
  }


  const downloadAllPackage = (type) => {
    exportFileService.exportFile(`/projects/${projectId}/categories/${type}`, `${projectId}-${type}`, {}, "zip")
    toast.success('Téléchargement en cours');
  }

  const handleDownload = (url, filename) => {
    if (url !== undefined && filename !== undefined) {
      ComService.getProjectMedia(url, filename);
      toast.success('Téléchargement en cours');
    } else {
      toast.error('Une erreur est survenue');
    }
  }

  const handleDeleteFile = async (id) => {
    setMediaToDelete(id);
    setShowPopupDelete(true);
  }

  const deleteMedia = async () => {
    let request = await ComService.delProjectMedia(mediaToDelete);
    if (request.status === 204) {
      setMediaToDelete(null);
      toast.success("Document supprimé avec succès");
      closePopup();
    }
  }

  const addMedia = async () => {
    let request = await ComService.postProjectMedia(file);
    if (request) {
      toast.success("Document ajouté avec succès");
      setMediaUploaded(!mediaUploaded);
      setFile(null);
      setShowPopupAdd(false);
    } else {
      toast.error("Une erreur est survenue");
    }
  }

  const closePopup = () => {
    setMediaToDelete(null);
    setShowPopupDelete(false);
    setShowPopupAdd(false);
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <section className='project-header'>
              <header className='project-header__banner'>
                {ProjectNiceType({ type: project.type, value: "bannerImg" })}
              </header>
              <div className='project-header__content'>
                <Link to={`/client/${project.client.id}`} className={"title-icon-nav-link"}>
                  <img src={"/images/pictos/arrow-left.svg"} alt="arrow left" />
                  <h1>Tableau de bord</h1>
                </Link>
                <span className={'color-dark-grey text-primary span-disabled'}>
                  {ProjectNiceStage({ stage: project.stage, niceStage: project.niceStage })}
                </span>
              </div>
            </section>
            <section className='project-container'>

              <div className='project-documents__header'>
                {
                  project.reportingNoticeIri ? (
                    <div className='project-documents__header-action'>
                      <div className='project-documents__header-action__indicator'>
                        <div>
                          <img src="/images/pictos/open-file-white.svg" alt="icon file" />
                        </div>
                        <p className="text-primary color-black">Avis de passage</p>
                      </div>
                      <div className='project-documents__header-action-icons'>
                        <img
                          onClick={() => downloadAvisDePassage(project.reportingNoticeIri)}
                          src="/images/pictos/download.svg"
                          alt="icon download"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='project-documents__header-action'>
                      <div className='project-documents__header-action__indicator'>
                        <div className='unavailable'>
                          <img src="/images/pictos/open-file-white.svg" alt="icon file" />
                        </div>
                        <p className="text-primary color-black">Avis de passage</p>
                      </div>
                    </div>
                  )
                }

                {
                  project.study && project.stage !== 'COM_payment' && project.stage !== 'ADMIN_payment-follow-up' && project.stage !== 'ADMIN_payment-validation' ? (
                    project.study.filename ? (
                      <div className='project-documents__header-action'>
                        <div className='project-documents__header-action__indicator'>
                          <div>
                            <img src="/images/pictos/file-white.svg" alt="icon file" />
                          </div>
                          <p className="text-primary color-black">Dossier d'étude structure</p>
                        </div>
                        <div className='project-documents__header-action-icons'>
                          <img
                            onClick={() => downloadStudy()}
                            src="/images/pictos/download.svg"
                            alt="icon download"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='project-documents__header-action'>
                        <div className='project-documents__header-action__indicator'>
                          <div className="unavailable">
                            <img src="/images/pictos/file-white.svg" alt="icon file" />
                          </div>
                          <p className="text-primary color-black">Dossier d'étude structure - Rédaction</p>
                        </div>
                      </div>
                    )
                  ) : (
                    project.study?.filename && !isClient ? (
                      <div className='project-documents__header-action'>
                        <div className='project-documents__header-action__indicator'>
                          <div>
                            <img src="/images/pictos/file-white.svg" alt="icon file" />
                          </div>
                          <p className="text-primary color-black">Dossier d'étude structure</p>
                        </div>
                        <div className='project-documents__header-action-icons'>
                          <img
                            onClick={() => downloadStudy()}
                            src="/images/pictos/download.svg"
                            alt="icon download"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='project-documents__header-action'>
                        <div className='project-documents__header-action__indicator'>
                          <div className="unavailable">
                            <img src="/images/pictos/file-white.svg" alt="icon file" />
                          </div>
                          <p className="text-primary color-black">Dossier d'étude structure - Non disponible</p>
                        </div>
                      </div>
                    )
                  )
                }
              </div>

              <div className='project-documents__container'>
                <div className='project-documents__container-admin'>
                <section className={"container-title-download-project"}>
                  <h4 className='color-primary'>Administratif</h4>
                  <img
                                onClick={() => downloadAllPackage('media-admin')}
                                src='/images/pictos/download.svg'
                                alt='icon download'
                              />
                </section>

                  {
                    medias['Administratif'] && (
                      medias['Administratif'].map((media, index) => {
                        return (
                          <div className='project-documents__container-admin__card' key={index}>
                            <div>
                              <FileMimeType mimeType={media.mimeType} />
                              <p className='text-primary color-primary'>{media.title}</p>
                            </div>
                            <img
                              onClick={() => handleDownload(media.downloadUrl, media.filename)}
                              src='/images/pictos/download.svg'
                              alt='icon download'
                            />
                          </div>
                        )
                      })
                    )
                  }
                </div>

                <div className='project-documents__container-projet'>
                  <h4 className='color-primary'>Projet</h4>

                  {
                    medias['Image projet'] && (
                      <section className='project-documents__container-projet__section'>
                        <section className={"container-title-download-project"}>
                          <p className='text-primary color-primary'>
                            Image de projet
                          </p>

                          <img
                              onClick={() => downloadAllPackage('media-project')}
                              src='/images/pictos/download.svg'
                              alt='icon download'
                            />
                        </section>

                        <div>
                          <article className='project-documents__container-projet__section-add'>
                            <input 
                              type='file'
                              id='addImgFileCard'
                              onChange={(e) => uploadFile(e.target.files[0], 'media-project', 'addImgFileCard')}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                            <label htmlFor='addImgFileCard'>
                              <img src='/images/pictos/cross-add-blue.svg' />
                            </label>
                          </article>
                          {
                            medias['Image projet'].map((media, index) => {
                              return (
                                <article key={index}>
                                  <img src='/images/pictos/red-cross.svg' onClick={() => handleDeleteFile(media.id)} />
                                  <a href={basicUrl + media.imageUrl} target="_blank" rel="noopener noreferrer" download>
                                    <img src={basicUrl + media.imageUrl} alt={media.title} />
                                  </a>
                                  <p className='text-primary color-primary'>{media.title}</p>
                                  <p className='text-primary color-primary'>{moment(media.createdAt).format('DD/MM/YYYY')} à {moment(media.createdAt).format('HH:mm')}</p>
                                </article>
                              )
                            })
                          }
                        </div>
                      </section>
                    )
                  }

                  {
                    medias['Plan'] && (
                      <section className='project-documents__container-projet__section'>
                        <section className={"container-title-download-project"}>
                          <p className='color-primary text-primary'>
                            Plans
                          </p>

                          <img
                              onClick={() => downloadAllPackage('media-plan')}
                              src='/images/pictos/download.svg'
                              alt='icon download'
                            />
                        </section>
                        <div>
                          <article className='project-documents__container-projet__section-add'>
                            <input type='file' id='addPlanFileCard' onChange={(e) => uploadFile(e.target.files[0], 'media-plan', 'addPlanFileCard')} />
                            <label htmlFor='addPlanFileCard'>
                              <img src='/images/pictos/cross-add-blue.svg' />
                            </label>
                          </article>
                          {
                            medias['Plan'].map((media, index) => {
                              return (
                                <article className='documents-card__doc' key={index}>
                                  <img src='/images/pictos/red-cross.svg' onClick={() => handleDeleteFile(media.id)} />
                                  <div onClick={() => handleDownload("/api/medias/" + media.id, media.filename)}>
                                    <FileMimeType mimeType={media.mimeType} />
                                    <p className='text-primary color-primary'>
                                      {media.filename.length > 20 ? media.filename.substring(0, 20) + '...' : media.filename}
                                    </p>
                                  </div>
                                  <p className='text-primary color-primary'>
                                    {media.title}
                                  </p>
                                  <p className='text-primary color-primary'>
                                    {moment(media.createdAt).format('DD/MM/YYYY')} à {moment(media.createdAt).format('HH:mm')}
                                  </p>
                                </article>
                              )
                            })
                          }
                        </div>
                      </section>
                    )
                  }

                  {
                    medias['Feuille de calcul'] && (
                      <section className='project-documents__container-projet__section'>
                        <section className={"container-title-download-project"}>
                          <p className='color-primary text-primary'>
                              Feuille de calcul
                          </p>
                          <img
                                onClick={() => downloadAllPackage('media-calc')}
                                src='/images/pictos/download.svg'
                                alt='icon download'
                              />
                        </section>
                        <div>
                          <article className='project-documents__container-projet__section-add'>
                            <input type='file' id='addCalcFileCard' onChange={(e) => uploadFile(e.target.files[0], 'media-calc', 'addCalcFileCard')} />
                            <label htmlFor='addCalcFileCard'>
                              <img src='/images/pictos/cross-add-blue.svg' />
                            </label>
                          </article>
                          {
                            medias['Feuille de calcul'].map((media, index) => {
                              return (
                                <article className='documents-card__doc' key={index}>
                                  <img src='/images/pictos/red-cross.svg' onClick={() => handleDeleteFile(media.id)} />
                                  <div onClick={() => handleDownload("/api/medias/" + media.id, media.filename)}>
                                    <FileMimeType mimeType={media.mimeType} />
                                    <p className='text-primary color-primary'>{
                                      media.filename.length > 20 ? media.filename.substring(0, 20) + '...' : media.filename
                                    }</p>
                                  </div>
                                  <p className='text-primary color-primary'>
                                    {media.title}
                                  </p>
                                  <p className='text-primary color-primary'>
                                    {moment(media.createdAt).format('DD/MM/YYYY')} à {moment(media.createdAt).format('HH:mm')}
                                  </p>
                                </article>
                              )
                            }
                            )}
                        </div>
                      </section>
                    )
                  }

                  {
                    !isClient && medias['Document ingénieur'] && (
                      <section className='project-documents__container-projet__section'>
                        <section className={"container-title-download-project"}>
                        <p className='color-primary text-primary'>
                          Document ingénieur
                        </p>
                        <img
                                onClick={() => downloadAllPackage('media-engineer')}
                                src='/images/pictos/download.svg'
                                alt='icon download'
                              />
                        </section>
                        <div>
                          <article className='project-documents__container-projet__section-add'>
                            <input type='file' id='addIngFileCard' onChange={(e) => uploadFile(e.target.files[0], 'media-engineer', 'addIngFileCard')} />
                            <label htmlFor='addIngFileCard'>
                              <img src='/images/pictos/cross-add-blue.svg' />
                            </label>
                          </article>
                          {
                            medias['Document ingénieur'].map((media, index) => {
                              return (
                                <article className='documents-card__doc' key={index}>
                                  <img src='/images/pictos/red-cross.svg' onClick={() => handleDeleteFile(media.id)} />
                                  <div onClick={() => handleDownload("/api/medias/" + media.id, media.filename)}>
                                    <FileMimeType mimeType={media.mimeType} />
                                    <p className='text-primary color-primary'>{
                                      media.filename.length > 20 ? media.filename.substring(0, 20) + '...' : media.filename
                                    }</p>
                                  </div>
                                  <p className='text-primary color-primary'>
                                    {media.title}
                                  </p>
                                  <p className='text-primary color-primary'>
                                    {moment(media.createdAt).format('DD/MM/YYYY')} à {moment(media.createdAt).format('HH:mm')}
                                  </p>
                                </article>
                              )
                            }
                            )}
                        </div>
                      </section>
                    )
                  }
                </div>
              </div>
            </section>

            <PopupDeleteMediaComs
              showPopup={showPopupDelete}
              closeAction={closePopup}
              validAction={deleteMedia}
            />

            <PopupAddMediaComs
              showPopup={showPopupAdd}
              closeAction={closePopup}
              file={file}
              errorFile={errorFile}
              validAction={addMedia}
            />

          </>
        )
      }
    </>
  );
}

export default ProjectMediasComs;
