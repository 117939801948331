import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import InputLabelErrorMessage from '../../../../Atomes/Formik/InputLabelErrorMessage';
import SchemaValidTasks from './Config/SchemaValidTasks';

import ApiService from '../../../../../services/APIService';

const PopupValidTask = ({ showPopup, closeAction, taskValidated, taskValidatedType, taskValidatedId, pro }) => {

  const timeToFloat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const floatTime = hours + (minutes / 60);
    return parseFloat(floatTime.toFixed(2));
  }

  const submitForm = async (values, setSubmitting) => {
    let effectiveDurationFloat = timeToFloat(values.effectiveDuration); 
    let data = {
      effectiveDuration: effectiveDurationFloat,
      categoryType: taskValidatedType,
      completed : true,
    }

    let request = await ApiService.patch(`assignments/${taskValidatedId}`, data);
    if (request.status === 200) {
      closeAction();
      taskValidated();
      toast.success("Temps passé sur la tâche enregistré");
    } else {
      toast.error("Erreur lors de l'enregistrement du temps passé sur la tâche");
    }
  }

  return (
    <div className={`pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple card-task-popup"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
        <p className="text-primary">Combien de temps avez-vous mis pour la réalisation de cette tâche ?</p>
        <Formik
          initialValues={{
            effectiveDuration: '00:00',
          }}
          validationSchema={SchemaValidTasks}
          // onSubmit={submitForm}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values, setSubmitting);
          }}
        > 
          {({ isSubmitting }) => (
            <Form>
              <div className={"form-row-element"}>
                <InputLabelErrorMessage
                  label="Temps passé sur la tâche (en heures et minutes)"
                  name="effectiveDuration"
                  type="time"
                />
              </div>
              <div className={"form-row-element"}>
                <button 
                  className="btn-default btn-default-primary"
                  type="submit"
                  disabled={isSubmitting ? true : false}
                >
                  Valider
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default PopupValidTask