import *  as Yup from 'yup';

const SchemaObjectiveTemp = Yup.object().shape({
    userPrevTime : Yup.string().required('Merci de renseigner le temps prévisionnel'),
    userRealTime: Yup.string().required('Merci de renseigner le temps passé')
});

export default SchemaObjectiveTemp;


