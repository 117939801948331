import React, {createContext, useEffect, useState} from 'react';
import TitleSection from "../../../../../components/Atomes/Title/TitleSection";
import FormDevis from "./Partials/FormDevis/FormDevis";
import DevisIntroChatBot from "./Partials/DevisIntroChatBot";
import FormAddArchitect from "../../../../../components/Molecules/Form/FormAddArchitect";
import {useParams} from "react-router-dom";
import RequestService from "../../../../../services/RequestService";
import {toast} from "react-toastify";
import Skeleton from "react-loading-skeleton";
import PopUpForm from "../../../../../components/Molecules/PopUp/PopUpForm";


export const StateRequestArch = createContext()

const Devis = () => {

	const [showPopUp, setShowPopUp] = useState(false);
	const DisplayCreateModalArchitect = () => {
		setShowPopUp(!showPopUp)
	}

	const [update, setUpdate] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const {id} = useParams()
	const [project, setProject] = useState();

	const getInfoRequest = async () => {
		setProject([])
		setIsLoading(true)
		const request = await RequestService.getProjectInformations(id)
		if (request.request.status === 200) {
			if (typeof request.data.partnerQuotes === 'object' && !Array.isArray(request.data.partnerQuotes)) {
				request.data.partnerQuotes = Object.values(request.data.partnerQuotes);
			}
			if (typeof request.data.quotes === 'object' && !Array.isArray(request.data.quotes)) {
				request.data.quotes = Object.values(request.data.quotes);
			}

			 // Mettre à jour les descriptions manquantes
			 request.data.quotes.forEach(quote => {
				quote.orders.forEach(order => {
					if (!order.description && order.service && order.service.description) {
						order.description = order.service.description;
					}
				});
			});

			setProject(request.data)
			setIsLoading(false)
			
		} else {
			toast.error('Une erreur est survenue lors de la récupération des information du devis')
		}
	}

	useEffect(() => {
		setProject()
		getInfoRequest()
	}, []);

	const [requestArchForm, setRequestArchForm] = useState("");

	useEffect(() => {
		if (requestArchForm === "success add") {
			toast.success("L'architecte a bien été ajouté")
			setTimeout(function () {
				setRequestArchForm("")
			}, 2000);
		} else if (requestArchForm === "error add") {
			toast.error("Une erreur est survenue lors de l'ajout de l'architect ")
		}
	}, [requestArchForm]);

	return (<div>
			<StateRequestArch.Provider value={{requestArchForm, setRequestArchForm}}>
				<TitleSection title={"Édition devis"}/>

				{project?.chatbot ?
					<DevisIntroChatBot isLoading={isLoading} request={project} /> :
					null
				}

				{isLoading ?
					<Skeleton height={1000}/> :
					<FormDevis
						requestProject={getInfoRequest}
						requestArchForm={requestArchForm}
						isLoading={isLoading}
						project={project}
						update={update}
						setUpdate={setUpdate}
						setShowPopUp={setShowPopUp}
					/>
				}

				<PopUpForm large={true} title={"Ajouter un gestionnaire"}
				           form={<FormAddArchitect setShowPopUp={setShowPopUp} project={project}
				                                   setRequestArchForm={setRequestArchForm}/>}
				           onClick={DisplayCreateModalArchitect} show={showPopUp}/>
			</StateRequestArch.Provider>
		</div>
	);
};

export default Devis;
