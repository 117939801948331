import api from "./APIService";
import { ConvertObjectToUrlParams} from "../utils/StringUtils";

const getCollaborators = async (url) => {
	return await api.getAllData(url)
}

const updateCollaborator = async (url, data) => {
	return await api.patch(url, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error);
		});
}

const patchPrevTemp = async (url, data) => {
	return await api.patch(url, data)
}

const addCollaborator = async (url, data) => {
	const request =  await api.postBody(url, data)
	return request
}

const getObjectifsCollaborator = async (url) => {
	return await api.getAllData(url)
}

const getCollaboratorObjective = async (url) => {
	return await api.getAllData(url)
}

const getSingleCollaborator = async (url) => {
	return await api.getAllData(url)
}

// Get Kpi of collaborator
const getCollaboratorAverageBasket = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/average-basket/${userId}`, param))
}

const getCollaboratorTransformRate = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/transform-rate/${userId}`, param))
}

const getCollaboratorRevenueByType = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/revenues-by-type/${userId}`, param) )
}

const getCollaboratorRevenue = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/revenues/${userId}`, param))
}

const getCollaboratorRevenueByMonth = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/revenues-by-month/${userId}`, param))
}

const getPastStudies = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("projects/context/engineer", param))
}

const updateQuotesPartner = async (url, data) => {
	return await api.patch(url, data)
}

const CollaboratorService = {
	getCollaborators,
	updateCollaborator,
	addCollaborator,
	getObjectifsCollaborator,
	getCollaboratorObjective,
	getCollaboratorAverageBasket,
	getCollaboratorTransformRate,
	getCollaboratorRevenueByType,
	getCollaboratorRevenue,
	getCollaboratorRevenueByMonth,
	getSingleCollaborator,
	getPastStudies,
	updateQuotesPartner,
	patchPrevTemp
}

export default CollaboratorService;
